import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { Typography, Button } from "@material-ui/core";
import BottomNav from "../../../Shared/BottomNav";
import * as moment from "moment";
import axios from "axios";
import querystring from "querystring";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Skeleton from "@material-ui/lab/Skeleton";
import IOSSwitch from "../../Settings/Main/IOSSwitch";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { makeStyles } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import "moment/locale/fr";
import "moment/locale/it";
import "./styles.css";
import Popup from "../../../Shared/Popup";
import EmailDialog from "../../../Shared/EmailDialog";

function start_and_end(str) {
  if (str.length > 35) {
    let newStr = str.split(" - ");
    newStr = newStr.map((str) => str.trim());
    newStr = newStr.map((str) => str.replace(/ *\([^)]*\) */g, ""));
    // console.log("STRING: ", newStr);
    return (
      // str.substr(0, 10) + " - ... - " + str.substr(str.length - 10, str.length)
      `${newStr[0]} - ... - ${newStr[newStr.length - 1]}`
    );
  }
  return str;
}

export const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
});

// const CustomToolbar = function (props) {
//   const { date, isLandscape, openView, setOpenView, title } = props;

//   const handleChangeViewClick = (view) => (e) => {
//     setOpenView(view);
//   };

//   const classes = useStyles();

//   return (
//     <PickerToolbar
//       className={classes.toolbar}
//       title={title}
//       isLandscape={isLandscape}
//     >
//       <ToolbarButton
//         onClick={handleChangeViewClick("year")}
//         variant='h6'
//         label={date.format("YYYY")}
//         selected={openView === "year"}
//       />
//       <ToolbarButton
//         onClick={handleChangeViewClick("Sélectionner la date du trajet")}
//         variant='h4'
//         selected={openView === "Sélectionner la date du trajet"}
//         label={date.format("dddd, D. MMM")}
//       />
//     </PickerToolbar>
//   );
// };

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: window.localStorage.dialog,
      dialogEmail: window.localStorage.dialogEmail,
      tripsData: "",
      dD: null,
      DepartureStationName: null,
      DepartureStationId: null,
      ArrivalStationName: null,
      ArrivalStationId: null,
      MeansOfTravel: null,
      ExtraStops: null,
      screenWidth: null,
      help: "firstTimeOutHome",
      emailNotification: false,
      isExpanded: "displayNone",
      firstTimeLogin: true,
      // expandableArray: [],
      emailToggleDisplay: false,
      emailToggleContent: {
        text: window.location.pathname.includes("/it")
          ? "Desidero ricevere ogni giorno un'e-mail che mi ricordi di rilevare i miei viaggi."
          : window.location.pathname.includes("/fr")
          ? "Je souhaite recevoir chaque jour un e-mail qui me rappellera de saisir mes trajets."
          : window.location.pathname.includes("/en")
          ? "I would like to be reminded by email every day to record my trips."
          : "Ich möchte täglich per E-Mail erinnert werden, meine Reisen zu erfassen.",
        text2: window.location.pathname.includes("/it")
          ? "(Puoi modificare questa impostazione in qualsiasi momento.)"
          : window.location.pathname.includes("/fr")
          ? "(Vous pouvez modifier ce paramètre à tout moment.)"
          : window.location.pathname.includes("/en")
          ? "(This setting can be changed at any time.)"
          : "(Sie können diese Einstellung jederzeit ändern.)",
      },
      popupDisplay: false,
      popup: {},
      fetching: true,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  expandHandle = () => {
    this.setState({ isExpanded: "displayBlock" });
  };

  dialogEmailHandle = () => {
    setTimeout(() => {}, 500);
    // if (this.state.tripsData === null) {
    //   this.dialogHandleOpen();
    // }
  };
  handleChangeEmail = (e) => {
    if (this.state.emailNotification === false) {
      this.setState({ emailNotification: true }, () => {
        this.updateEmailPut(true);
      });
    } else {
      this.setState({ emailNotification: false });
      this.updateEmailPut(false);
    }
  };
  dialogHandleOpen = () => {
    this.togglePopup();
  };

  //Dispaly/Hide the email dialog/popup on main screen
  toggleEmailPopup = (e) => {
    if (window.localStorage.firstTimeLogin)
      window.localStorage.setItem("emailPopup", true);
    if (e) {
      if (e.target === e.currentTarget)
        this.setState(
          { emailToggleDisplay: !this.state.emailToggleDisplay },
          () => {
            if (!this.state.emailToggleDisplay && this.state.screenWidth < 620)
              setTimeout(() => {
                this.togglePopup();
              }, 1000);
          }
        );
      return;
    }
    this.setState(
      { emailToggleDisplay: !this.state.emailToggleDisplay },
      () => {
        if (!this.state.emailToggleDisplay && this.state.screenWidth < 620)
          setTimeout(() => {
            this.togglePopup();
          }, 1000);
      }
    );
  };
  //Toggle only email popup for desktop users
  // toggleEmailPopupDesktop = (e) => {
  //   console.log("toggle for desktop");
  //   if (e) {
  //     if (e.target === e.currentTarget)
  //       this.setState({ emailToggleDisplay: !this.state.emailToggleDisplay });
  //     return;
  //   }
  //   this.setState({ emailToggleDisplay: !this.state.emailToggleDisplay });
  // };

  componentDidMount() {
    // console.log("PROCESS ENV: ", process.env);
    window.addEventListener("resize", this.updateWindowDimensions());
    this.userProfileHandler();

    this.props.backButtonChecker();
    // setTimeout(() => {
    //   this.userProfileHandler();
    //   console.log("SETTIMEOUT SEC RAN:");
    // }, 1000);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ screenWidth: window.innerWidth });
  }
  updateEmailPut = (x) => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/authentication/EditUser`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "lang=de");

    var raw = JSON.stringify({
      Id: this.state.userInfo.Id,
      Name: this.state.userInfo.Name,
      IsAdmin: this.state.userInfo.IsAdmin,
      IsRegistered: this.state.userInfo.IsRegistered,
      EnableDailyMailReminder: x,
      EnablePushNotificationReminder: false,
      Preload: {
        UserEmail: this.state.userInfo.Preload.UserEmail,
        Salutation: this.state.userInfo.Preload.Salutation,
        Pin: this.state.userInfo.Preload.Pin,
        PeriodStart: this.state.userInfo.Preload.PeriodStart,
        PeriodEnd: this.state.userInfo.Preload.PeriodEnd,
        SubmissionDeadline: this.state.userInfo.Preload.SubmissionDeadline,
        Email: this.state.userInfo.Preload.Email,
        ChangedBy: this.state.userInfo.Preload.ChangedBy,
        MailSalutation: this.state.userInfo.Preload.MailSalutation,
        NoTravelsReason: this.state.userInfo.Preload.NoTravelsReason,
        Notes: this.state.userInfo.Preload.Notes,
        UniqueId: this.state.userInfo.Preload.UniqueId,
        ContestParticipation: this.state.userInfo.Preload.ContestParticipation,
        ImportDate: this.state.userInfo.Preload.ImportDate,
        ActivationDate: this.state.userInfo.Preload.ActivationDate,
        LockDate: this.state.userInfo.Preload.LockDate,
        TNC: this.state.userInfo.Preload.TNC,
        ActivationOpened: this.state.userInfo.Preload.ActivationOpened,
        ReiseblattNr: this.state.userInfo.Preload.ReiseblattNr,
        Sex: this.state.userInfo.Preload.Sex,
        Firstname: this.state.userInfo.Preload.Firstname,
        Name: this.state.userInfo.Preload.Name,
        Address1: this.state.userInfo.Preload.Address1,
        Address2: this.state.userInfo.Preload.Address2,
        ZipCode: this.state.userInfo.Preload.ZipCode,
        Place: this.state.userInfo.Preload.Place,
        Country: this.state.userInfo.Preload.Country,
        Language: this.state.userInfo.Preload.Language,
        ProjectCode: this.state.userInfo.Preload.ProjectCode,
        AboType: this.state.userInfo.Preload.AboType,
        LockSource: this.state.userInfo.Preload.LockSource,
        Id: this.state.userInfo.Preload.Id,
        Created: this.state.userInfo.Preload.Created,
        Changed: this.state.userInfo.Preload.Changed,
      },
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.text())
      // .then(localStorage.clear())
      // .then(this.handleSubmit(this.state.userProfile.TNC))
      .catch((error) => console.log("error", error));
  };

  handleSubmit = (param) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/token`,
        querystring.stringify({
          TNC: param,
          grant_type: "password",
        })
      )

      .then((response) => {
        window.localStorage.setItem(
          "AccessToken",
          "Bearer " + response.data.access_token
        );
      })
      .catch((error) => console.log("error", error));
  };
  handleUser = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/Journeys/Journeys`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState(
          {
            tripsData: result,
            // lastTrip: result[result.length - 1],
            fetchedTrips: true,
          }
          // () => {
          //   if (this.state.tripsData !== null) {
          //     let lasttripdate = moment(
          //       this.state.tripsData[this.state.tripsData.length - 1]
          //         .JourneyDate
          //     );
          //     let startPeriod = moment(this.state.userProfile.PeriodStart);
          //     var dateWithLastTrip = lasttripdate.diff(startPeriod, "days");
          //     this.setState((prevState) => {
          //       return {
          //         expandableArray: [
          //           ...prevState.expandableArray,
          //           (prevState.expandableArray[dateWithLastTrip] = true),
          //         ],
          //       };
          //     });
          //   }
          // }
        );
      })
      .catch((error) => console.log("error", error));
  };

  userProfileHandler = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/authentication/userInfo`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState(
          {
            userInfo: result,
            userProfile: result.Preload,
            emailNotification: result.EnableDailyMailReminder,
            popup: {
              text: window.location.pathname.includes("/fr")
                ? `Veuillez saisir tous les trajets que vous avez effectués avec votre
                  abonnement général (AG) du ${moment(
                    result.Preload.PeriodStart
                  ).format("DD.MM.YYYY")} au ${moment(
                    result.Preload.PeriodEnd
                  ).format(
                    "DD.MM.YYYY"
                  )}. Indiquez le nom exact de l'arrêt et tous les arrêts où vous avez
                  changé de véhicule. Merci de répondre à l'enquête d'ici au ${moment(
                    result.Preload.SubmissionDeadline
                  ).format("DD.MM.YYYY")}.`
                : window.location.pathname.includes("/it")
                ? `La preghiamo di registrare tutti i viaggi effettuati con il suo abbonamento generale (AG) dal ${moment(
                    result.Preload.PeriodStart
                  ).format("DD.MM.YYYY")} al ${moment(
                    result.Preload.PeriodEnd
                  ).format(
                    "DD.MM.YYYY"
                  )}. Indichi ogni volta la fermata esatta e tutti i punti di cambio.
                  Concluda il sondaggio entro il ${moment(
                    result.Preload.SubmissionDeadline
                  ).format("DD.MM.YYYY")}.`
                : window.location.pathname.includes("/en")
                ? `Please enter ALL the journeys that you made in the period from ${moment(
                    result.Preload.PeriodStart
                  ).format("DD.MM.YYYY")} to ${moment(
                    result.Preload.PeriodEnd
                  ).format(
                    "DD.MM.YYYY"
                  )} using your GA travelcard. Please state the exact stops and all the places where you had connections. Close your travel diary by ${moment(
                    result.Preload.SubmissionDeadline
                  ).format("DD.MM.YYYY")}.`
                : `Bitte erfassen Sie alle Reisen, die Sie mit Ihrem BÜGA vom
                    ${moment(result.Preload.PeriodStart).format(
                    "DD.MM.YYYY"
                  )} bis ${moment(result.Preload.PeriodEnd).format(
                    "DD.MM.YYYY"
                  )} unternommen haben. Geben Sie dabei jeweils die genaue Haltestelle und alle Umsteigeorte an. Beenden Sie die Umfrage bitte bis am ${moment(
                    result.Preload.SubmissionDeadline
                  ).format("DD.MM.YYYY")}.`,
            },
          },
          () => {
            this.setState({ fetching: false });
            // let endDate = moment(this.state.userProfile.PeriodEnd);
            // let startDate = moment(this.state.userProfile.PeriodStart);
            // let integerDifference = endDate.diff(startDate, "days");
            // let arrFalse = [];
            // for (let i = 0; i <= integerDifference; i++) {
            //   arrFalse.push(false);
            // }
            // this.setState({ expandableArray: arrFalse });

            if (
              window.localStorage.firstTimeLogin &&
              !window.localStorage.mainDialog &&
              !window.localStorage.emailPopup
            ) {
              this.toggleEmailPopup();
            }
            this.handleUser();
          }
        );

        if (result.IsRegistered === false) {
          // this.props.history.push("/register");
          // window.location.reload();
        }
      })
      .catch((error) => console.log("error", error));
  };

  deleteConfirmation = (e, id) => {
    e.stopPropagation();
    var element = document.getElementById("delete" + id);
    element.classList.add("confirmDelete");
    element.classList.remove("Delete");
  };

  deleteConfirmationCancel = (e, id) => {
    e.stopPropagation();
    var element = document.getElementById("delete" + id);
    element.classList.add("Delete");
    element.classList.remove("confirmDelete");
  };

  editConfirmation = (e, id) => {
    e.stopPropagation();
    var element = document.getElementById("edit" + id);
    element.classList.add("confirmEdit");
    element.classList.remove("Edit");
  };

  editConfirmationCancel = (e, id) => {
    e.stopPropagation();
    var element = document.getElementById("edit" + id);
    element.classList.add("Edit");
    element.classList.remove("confirmEdit");
  };

  openDatePicker() {
    document.getElementById("datepicker").click();
  }

  updateDatePicker(e, dsn, dsi, asn, asi, mot, es) {
    // console.log('DATE PICkER ID: ', e);
    document.getElementById("datepickerUpdate" + e).click();

    this.setState({
      DepartureStationName: dsn,
      DepartureStationId: dsi,
      ArrivalStationName: asn,
      ArrivalStationId: asi,
      MeansOfTravel: mot,
      ExtraStops: es,
    });
  }

  //Function to hide popup overlay
  togglePopup = (e) => {
    if (window.localStorage.firstTimeLogin)
      window.localStorage.setItem("mainDialog", true);
    if (e) {
      if (e.target === e.currentTarget)
        this.setState({ popupDisplay: !this.state.popupDisplay });
      return;
    }
    this.setState({ popupDisplay: !this.state.popupDisplay });
  };
  //Click on addbutton handler
  clickHandler = (e, date, from, index) => {
    e.stopPropagation();
    const panelUI = document.querySelector(`#panel${index}`);
    // panelUI.classList.toggle("customGray");
    panelUI.classList.toggle("onClickColors");
    setTimeout(() => {
      this.props.handleDateChange(date, from);
    }, 300);
  };
  //Mouse over handlers
  mouseEnterHandler = (index) => {
    // const panelUI = document.querySelector(`#panel${index}`);
    const buttonUI = document.querySelector(`#btn${index} > svg`);
    buttonUI.classList.toggle("rotateIconForward");
  };
  mouseLeaveHandler = (index) => {
    // const panelUI = document.querySelector(`#panel${index}`);
    const buttonUI = document.querySelector(`#btn${index} > svg`);
    buttonUI.classList.toggle("rotateIconForward");
  };

  render() {
    var date = new Date();
    // eslint-disable-next-line
    var newDate = new Date(date);
    if (this.state.tripsData !== null) {
      var lastTrip = this.state.tripsData[this.state.tripsData.length - 1];
    }

    // eslint-disable-next-line no-lone-blocks
    {
      window.location.pathname.includes("/fr")
        ? moment.locale("fr")
        : window.location.pathname.includes("/it")
        ? moment.locale("it")
        : window.location.pathname.includes("/en")
        ? moment.locale("en")
        : moment.locale("de");
    }
    // eslint-disable-next-line
    var date = moment(this.props.state.userProfile.PeriodEnd);
    var dateStart = moment(this.props.state.userProfile.PeriodStart);
    var difference = dateStart.diff(date, "days");
    var diffDays = [];
    var exists = false;
    // const extendableValues = [];
    // let index = Math.abs(difference);

    while (difference < 1) {
      // extendableValues.push(false);
      diffDays.push(difference);
      difference++;
      // index++;
    }

    // console.log('TRIPS: ', this.state.tripsData);
    // console.log('DATE: ', diffDays);
    // if (this.props.state.userProfile.Email === undefined) {
    // } else {
    //   this.props.history.push('/?A=' + result.Preload.Pin + result.Preload.ProjectCode + result.Preload.Pin)
    //   console.log('L');
    // }

    // const handleRowClick = (index) => {
    //   if (this.state.expandableArray.length > 0) {
    //     let prevState = this.state.expandableArray;
    //     prevState[index] = !prevState[index];
    //     // console.log("Prev State  ", prevState);
    //     this.setState({
    //       expandableArray: prevState,
    //     });
    //   } else {
    //     extendableValues[index] = !extendableValues[index];
    //     console.log(extendableValues);
    //     this.setState({
    //       expandableArray: extendableValues,
    //     });
    //   }
    // };

    var counter;
    return (
      <React.Fragment>
        <CssBaseline />
        <Popup
          display={this.state.popupDisplay}
          content={this.state.popup}
          hidePopup={this.togglePopup}
        />
        <EmailDialog
          displayEmailToggle={this.state.emailToggleDisplay}
          content={this.state.emailToggleContent}
          hidePopup={this.toggleEmailPopup}
          emailToggle={
            <IOSSwitch
              checked={this.state.emailNotification}
              onChange={this.handleChangeEmail}
              name='emailNotifications'
              inputProps={{ "aria-label": "email notifications" }}
              className='emailSwitchonMain'
            />
          }
        />
        {/* Info overlay  */}
        <div className='_container'>
          {this.state.screenWidth > 620 && !this.state.fetching && (
            <Container
              maxWidth={false}
              disableGutters
              className='infoContainer'
            >
              {window.location.pathname.includes("/fr") ? (
                <Typography variant='h5' className='firstTimeDialogContent'>
                  Veuillez saisir tous les trajets que vous avez effectués avec
                  votre abonnement général (AG) du{" "}
                  {moment(this.state.userProfile.PeriodStart).format(
                    "DD.MM.YYYY"
                  )}{" "}
                  au{" "}
                  {moment(this.state.userProfile.PeriodEnd).format(
                    "DD.MM.YYYY"
                  )}
                  . Indiquez le nom exact de l'arrêt et tous les arrêts où vous
                  avez changé de véhicule. Merci de répondre à l'enquête d'ici
                  au{" "}
                  {moment(this.state.userProfile.SubmissionDeadline).format(
                    "DD.MM.YYYY"
                  )}
                  .
                </Typography>
              ) : window.location.pathname.includes("/it") ? (
                <Typography variant='h5' className='firstTimeDialogContent'>
                  La preghiamo di registrare tutti i viaggi effettuati con il
                  suo abbonamento BÜGA dal{" "}
                  {moment(this.state.userProfile.PeriodStart).format(
                    "DD.MM.YYYY"
                  )}{" "}
                  al{" "}
                  {moment(this.state.userProfile.PeriodEnd).format(
                    "DD.MM.YYYY"
                  )}
                  . Indichi ogni volta la fermata esatta e tutti i punti di
                  cambio. Concluda il sondaggio entro il{" "}
                  {moment(this.state.userProfile.SubmissionDeadline).format(
                    "DD.MM.YYYY"
                  )}
                  .
                </Typography>
              ) : window.location.pathname.includes("/en") ? (
                <Typography variant='h5' className='firstTimeDialogContent'>
                  Please enter ALL the journeys that you made in the period from{" "}
                  {moment(this.state.userProfile.PeriodStart).format(
                    "DD.MM.YYYY"
                  )}{" "}
                  to{" "}
                  {moment(this.state.userProfile.PeriodEnd).format(
                    "DD.MM.YYYY"
                  )}{" "}
                  using your GA travelcard. Please state the exact stops and all
                  the places where you had connections. Close your travel diary
                  by{" "}
                  {moment(this.state.userProfile.SubmissionDeadline).format(
                    "DD.MM.YYYY"
                  )}
                  .
                </Typography>
              ) : (
                <Typography variant='h5' className='firstTimeDialogContent'>
                 Bitte erfassen Sie alle Reisen, die Sie mit Ihrem BÜGA vom{" "}
                  {moment(this.state.userProfile.PeriodStart).format(
                    "DD.MM.YYYY"
                  )}{" "}
                  bis{" "}
                  {moment(this.state.userProfile.PeriodEnd).format(
                    "DD.MM.YYYY"
                  )}{" "}
                  unternommen haben. Geben Sie dabei jeweils die genaue
                  Haltestelle und alle Umsteigeorte an. Beenden Sie die Umfrage
                  bitte bis am{" "}
                  {moment(this.state.userProfile.SubmissionDeadline).format(
                    "DD.MM.YYYY"
                  )}
                  .
                </Typography>
              )}
            </Container>
          )}

          {/* If  there are no trips */}
          {this.state.tripsData === null ? (
            <Container maxWidth={false} disableGutters={true}>
              <Container
                maxWidth={false}
                disableGutters={true}
                className='tripsList'
              >
                {diffDays.map((i, index) => (
                  <ExpansionPanel
                    square={true}
                    // expanded={false}
                    key={i}
                    className='expanPan'
                    // onClick={() => handleRowClick(index)}
                  >
                    {window.location.pathname.includes("/fr") ? (
                      <ExpansionPanelSummary
                        style={{ backgroundColor: "#EEEEEE" }}
                        className='expanPanSum accordonMain'
                        id={`panel${index}`}
                      >
                        {moment(date).add(i, "day").format("dddd, D.MM.YYYY ")}
                        <span>{"- " + 0 + " trajets"}</span>
                        <div
                          className='addIconWrapper'
                          onMouseEnter={(e) => this.mouseEnterHandler(index)}
                          onMouseLeave={(e) => this.mouseLeaveHandler(index)}
                          id={`btn${index}`}
                        >
                          <AddCircleIcon
                            className='returningAddIcon'
                            onClick={(e) => {
                              this.clickHandler(
                                e,
                                new Date(
                                  moment(
                                    this.props.state.userProfile.PeriodEnd
                                  ).subtract(+Math.abs(i), "day")
                                ),
                                "plusButton",
                                index
                              );
                            }}
                          />
                        </div>
                      </ExpansionPanelSummary>
                    ) : window.location.pathname.includes("/it") ? (
                      <ExpansionPanelSummary
                        style={{ backgroundColor: "#EEEEEE" }}
                        className='expanPanSum accordonMain'
                        id={`panel${index}`}
                      >
                        {moment(date).add(i, "day").format("dddd, D.MM.YYYY ")}
                        <span>{"- " + 0 + " viaggi"}</span>
                        <div
                          className='addIconWrapper'
                          onMouseEnter={(e) => this.mouseEnterHandler(index)}
                          onMouseLeave={(e) => this.mouseLeaveHandler(index)}
                          id={`btn${index}`}
                        >
                          <AddCircleIcon
                            className='returningAddIcon'
                            onClick={(e) => {
                              this.clickHandler(
                                e,
                                new Date(
                                  moment(
                                    this.props.state.userProfile.PeriodEnd
                                  ).subtract(+Math.abs(i), "day")
                                ),
                                "plusButton",
                                index
                              );
                            }}
                          />
                        </div>
                      </ExpansionPanelSummary>
                    ) : window.location.pathname.includes("/en") ? (
                      <ExpansionPanelSummary
                        style={{ backgroundColor: "#EEEEEE" }}
                        className='expanPanSum accordonMain'
                        id={`panel${index}`}
                      >
                        {moment(date).add(i, "day").format("dddd, D.MM.YYYY ")}
                        <span>{"- " + 0 + " Journeys"}</span>
                        <div
                          className='addIconWrapper'
                          onMouseEnter={(e) => this.mouseEnterHandler(index)}
                          onMouseLeave={(e) => this.mouseLeaveHandler(index)}
                          id={`btn${index}`}
                        >
                          <AddCircleIcon
                            className='returningAddIcon'
                            onClick={(e) => {
                              this.clickHandler(
                                e,
                                new Date(
                                  moment(
                                    this.props.state.userProfile.PeriodEnd
                                  ).subtract(+Math.abs(i), "day")
                                ),
                                "plusButton",
                                index
                              );
                            }}
                          />
                        </div>
                      </ExpansionPanelSummary>
                    ) : (
                      <ExpansionPanelSummary
                        style={{ backgroundColor: "#EEEEEE" }}
                        className='expanPanSum accordonMain'
                        id={`panel${index}`}
                      >
                        {moment(date).add(i, "day").format("dddd, D.MM.YYYY ")}
                        <span>{"- " + 0 + " Reisen"}</span>
                        <div
                          className='addIconWrapper'
                          onMouseEnter={(e) => this.mouseEnterHandler(index)}
                          onMouseLeave={(e) => this.mouseLeaveHandler(index)}
                          id={`btn${index}`}
                        >
                          <AddCircleIcon
                            className='returningAddIcon'
                            onClick={(e) => {
                              this.clickHandler(
                                e,
                                new Date(
                                  moment(
                                    this.props.state.userProfile.PeriodEnd
                                  ).subtract(+Math.abs(i), "day")
                                ),
                                "plusButton",
                                index
                              );
                            }}
                          />
                        </div>
                      </ExpansionPanelSummary>
                    )}
                  </ExpansionPanel>
                ))}
              </Container>
            </Container>
          ) : this.state.tripsData.length > 0 ? (
            <Container maxWidth={false} disableGutters={true}>
              <Container
                maxWidth={false}
                disableGutters={true}
                className='tripsList'
              >
                {diffDays.map((i, index) => {
                  return (
                    <ExpansionPanel
                      square={true}
                      // expanded={false}
                      key={i}
                      // onClick={() => handleRowClick(index)}
                      className={
                        "expanPan " +
                        (this.state.tripsData.forEach((trip, index) => {
                          if (
                            moment(trip.JourneyDate).format("DD.MM.YYYY") ===
                            moment(date).add(i, "day").format("DD.MM.YYYY")
                          ) {
                            exists = true;
                          }
                        }),
                        exists ? "" : "customGray")
                      }
                      asd={(exists = false)}
                      defaultExpanded={
                        //Opens the accordion that has the last trip
                        moment(date).add(i, "day").format("DD.MM.YYYY") ===
                        moment(lastTrip.JourneyDate).format("DD.MM.YYYY")
                          ? true
                          : false
                      }
                    >
                      {window.location.pathname.includes("/fr") ? (
                        <ExpansionPanelSummary
                          className='expanPanSum accordonMain'
                          id={`panel${index}`}
                        >
                          {moment(date)
                            .add(i, "day")
                            .format("dddd, D.MM.YYYY ")}
                          <span>
                            {
                              ((counter = 0),
                              this.state.tripsData.map((trip, index) =>
                                moment(trip.JourneyDate).format(
                                  "DD.MM.YYYY"
                                ) ===
                                moment(date).add(i, "day").format("DD.MM.YYYY")
                                  ? (counter = counter + 1)
                                  : null
                              ),
                              counter === 1
                                ? "– " + counter + " trajet"
                                : "– " + counter + " trajets")
                            }
                          </span>
                          <div
                            className='addIconWrapper'
                            onMouseEnter={(e) => this.mouseEnterHandler(index)}
                            onMouseLeave={(e) => this.mouseLeaveHandler(index)}
                            id={`btn${index}`}
                          >
                            <AddCircleIcon
                              className='returningAddIcon'
                              onClick={(e) => {
                                this.clickHandler(
                                  e,
                                  new Date(
                                    moment(
                                      this.props.state.userProfile.PeriodEnd
                                    ).subtract(+Math.abs(i), "day")
                                  ),
                                  "plusButton",
                                  index
                                );
                              }}
                            />
                          </div>
                        </ExpansionPanelSummary>
                      ) : window.location.pathname.includes("/it") ? (
                        <ExpansionPanelSummary
                          className='expanPanSum accordonMain'
                          id={`panel${index}`}
                        >
                          {moment(date)
                            .add(i, "day")
                            .format("dddd, D.MM.YYYY ")}
                          <span>
                            {
                              ((counter = 0),
                              this.state.tripsData.map((trip, index) =>
                                moment(trip.JourneyDate).format(
                                  "DD.MM.YYYY"
                                ) ===
                                moment(date).add(i, "day").format("DD.MM.YYYY")
                                  ? (counter = counter + 1)
                                  : null
                              ),
                              counter === 1
                                ? "– " + counter + " viaggio"
                                : "– " + counter + " viaggi")
                            }
                          </span>
                          <div
                            className='addIconWrapper'
                            onMouseEnter={(e) => this.mouseEnterHandler(index)}
                            onMouseLeave={(e) => this.mouseLeaveHandler(index)}
                            id={`btn${index}`}
                          >
                            <AddCircleIcon
                              className='returningAddIcon'
                              onClick={(e) => {
                                this.clickHandler(
                                  e,
                                  new Date(
                                    moment(
                                      this.props.state.userProfile.PeriodEnd
                                    ).subtract(+Math.abs(i), "day")
                                  ),
                                  "plusButton",
                                  index
                                );
                              }}
                            />
                          </div>
                        </ExpansionPanelSummary>
                      ) : window.location.pathname.includes("/en") ? (
                        <ExpansionPanelSummary
                          className='expanPanSum accordonMain'
                          id={`panel${index}`}
                        >
                          {moment(date)
                            .add(i, "day")
                            .format("dddd, D.MM.YYYY ")}
                          <span>
                            {
                              ((counter = 0),
                              this.state.tripsData.map((trip, index) =>
                                moment(trip.JourneyDate).format(
                                  "DD.MM.YYYY"
                                ) ===
                                moment(date).add(i, "day").format("DD.MM.YYYY")
                                  ? (counter = counter + 1)
                                  : null
                              ),
                              counter === 1
                                ? "– " + counter + " Journeys"
                                : "– " + counter + " Journeys")
                            }
                          </span>
                          <div
                            className='addIconWrapper'
                            onMouseEnter={(e) => this.mouseEnterHandler(index)}
                            onMouseLeave={(e) => this.mouseLeaveHandler(index)}
                            id={`btn${index}`}
                          >
                            <AddCircleIcon
                              className='returningAddIcon'
                              onClick={(e) => {
                                this.clickHandler(
                                  e,
                                  new Date(
                                    moment(
                                      this.props.state.userProfile.PeriodEnd
                                    ).subtract(+Math.abs(i), "day")
                                  ),
                                  "plusButton",
                                  index
                                );
                              }}
                            />
                          </div>
                        </ExpansionPanelSummary>
                      ) : (
                        <ExpansionPanelSummary
                          className='expanPanSum accordonMain'
                          id={`panel${index}`}
                        >
                          {moment(date)
                            .add(i, "day")
                            .format("dddd, D.MM.YYYY ")}
                          <span>
                            {
                              ((counter = 0),
                              this.state.tripsData.map((trip, index) =>
                                moment(trip.JourneyDate).format(
                                  "DD.MM.YYYY"
                                ) ===
                                moment(date).add(i, "day").format("DD.MM.YYYY")
                                  ? (counter = counter + 1)
                                  : null
                              ),
                              counter === 1
                                ? "– " + counter + " Reise"
                                : "– " + counter + " Reisen")
                            }
                          </span>
                          <div
                            className='addIconWrapper'
                            onMouseEnter={(e) => this.mouseEnterHandler(index)}
                            onMouseLeave={(e) => this.mouseLeaveHandler(index)}
                            id={`btn${index}`}
                          >
                            <AddCircleIcon
                              className='returningAddIcon'
                              onClick={(e) => {
                                this.clickHandler(
                                  e,
                                  new Date(
                                    moment(
                                      this.props.state.userProfile.PeriodEnd
                                    ).subtract(+Math.abs(i), "day")
                                  ),
                                  "plusButton",
                                  index
                                );
                              }}
                            />
                          </div>
                        </ExpansionPanelSummary>
                      )}
                      <ExpansionPanelDetails className='panelDetails'>
                        {this.state.tripsData.map((trip, index) =>
                          moment(trip.JourneyDate).format("DD.MM.YYYY") ===
                          moment(date).add(i, "day").format("DD.MM.YYYY") ? (
                            <ExpansionPanel key={index} className='expanPan'>
                              <ExpansionPanelSummary className='expanPanSum'>
                                <List>
                                  <ListItem
                                    className='listItem expandableList'
                                    disableGutters={true}
                                  >
                                    <ListItemText
                                      primary={
                                        this.state.screenWidth < 620
                                          ? start_and_end(
                                              trip.JourneySummary,
                                              trip.ExtraStops.length
                                            )
                                          : trip.JourneySummary
                                      }
                                    />
                                    <Button
                                      onClick={(e) =>
                                        this.editConfirmation(e, trip.JourneyId)
                                      }
                                      className='homeEditButton'
                                    >
                                      <EditOutlinedIcon className='homeInteractions' />
                                    </Button>
                                    <Button
                                      onClick={(e) =>
                                        this.deleteConfirmation(
                                          e,
                                          trip.JourneyId
                                        )
                                      }
                                      className='homeIntButton'
                                    >
                                      <DeleteOutlinedIcon className='homeInteractions' />
                                    </Button>
                                  </ListItem>
                                  <ListItem
                                    id={"delete" + trip.JourneyId}
                                    className='listItem Delete'
                                  >
                                    {window.location.pathname.includes(
                                      "/fr"
                                    ) ? (
                                      <ListItemText primary='Supprimer le trajet?' />
                                    ) : window.location.pathname.includes(
                                        "/it"
                                      ) ? (
                                      <ListItemText primary='Cancellare viaggio?' />
                                    ) : window.location.pathname.includes(
                                        "/en"
                                      ) ? (
                                      <ListItemText primary='Delete trip?' />
                                    ) : (
                                      <ListItemText primary='Reise löschen?' />
                                    )}
                                    <Button
                                      onClick={(e) =>
                                        this.props.handleDeleteTrip(
                                          trip.JourneyId,
                                          e
                                        )
                                      }
                                    >
                                      <CheckCircleOutlineRoundedIcon />
                                    </Button>
                                    <Button
                                      onClick={(e) =>
                                        this.deleteConfirmationCancel(
                                          e,
                                          trip.JourneyId
                                        )
                                      }
                                    >
                                      <HighlightOffRoundedIcon />
                                    </Button>
                                  </ListItem>
                                  <ListItem
                                    id={"edit" + trip.JourneyId}
                                    className='listItem Edit'
                                  >
                                    {window.location.pathname.includes(
                                      "/fr"
                                    ) ? (
                                      <ListItemText primary='Modifier le trajet?' />
                                    ) : window.location.pathname.includes(
                                        "/it"
                                      ) ? (
                                      <ListItemText primary='Modificare viaggio?' />
                                    ) : window.location.pathname.includes(
                                        "/en"
                                      ) ? (
                                      <ListItemText primary='Edit trip?' />
                                    ) : (
                                      <ListItemText primary='Reise bearbeiten?' />
                                    )}
                                    <Button
                                      onClick={() =>
                                        this.props.handleEditTrip(
                                          trip.JourneyId,
                                          trip.JourneyDate,
                                          trip.DepartureStationName,
                                          trip.DepartureStationId,
                                          trip.ArrivalStationName,
                                          trip.ArrivalStationId,
                                          trip.MeansOfTravel,
                                          trip.ExtraStops
                                        )
                                      }
                                    >
                                      <CheckCircleOutlineRoundedIcon />
                                    </Button>
                                    <Button
                                      onClick={(e) =>
                                        this.editConfirmationCancel(
                                          e,
                                          trip.JourneyId
                                        )
                                      }
                                    >
                                      <HighlightOffRoundedIcon />
                                    </Button>
                                  </ListItem>
                                </List>
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails className='tripSumary'>
                                <Typography>{trip.JourneySummary}</Typography>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          ) : null
                        )}
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  );
                })}
              </Container>
            </Container>
          ) : (
            <Container
              className='tripsList'
              maxWidth={false}
              disableGutters={true}
            >
              <Container maxWidth={false} disableGutters={true}>
                <List>
                  <ListItem className='topListItem'>
                    <Skeleton animation='wave' className='skeletonText' />
                    <Typography className='spacer'></Typography>
                    <Skeleton
                      variant='rect'
                      width={32}
                      height={32}
                      className='rectSkeleton'
                    />
                    <Skeleton
                      variant='rect'
                      width={32}
                      height={32}
                      className='rectSkeleton'
                    />
                    <Skeleton
                      variant='rect'
                      width={32}
                      height={32}
                      className='rectSkeleton'
                    />
                  </ListItem>
                  <ListItem className='bottomListItem'>
                    <Skeleton animation='wave' className='skeletonTextSecond' />
                  </ListItem>
                </List>
                <List>
                  <ListItem className='topListItem'>
                    <Skeleton animation='wave' className='skeletonText' />
                    <Typography className='spacer'></Typography>
                    <Skeleton
                      variant='rect'
                      width={32}
                      height={32}
                      className='rectSkeleton'
                    />
                    <Skeleton
                      variant='rect'
                      width={32}
                      height={32}
                      className='rectSkeleton'
                    />
                    <Skeleton
                      variant='rect'
                      width={32}
                      height={32}
                      className='rectSkeleton'
                    />
                  </ListItem>
                  <ListItem className='bottomListItem'>
                    <Skeleton animation='wave' className='skeletonTextSecond' />
                  </ListItem>
                </List>
                <List>
                  <ListItem className='topListItem'>
                    <Skeleton animation='wave' className='skeletonText' />
                    <Typography className='spacer'></Typography>
                    <Skeleton
                      variant='rect'
                      width={32}
                      height={32}
                      className='rectSkeleton'
                    />
                    <Skeleton
                      variant='rect'
                      width={32}
                      height={32}
                      className='rectSkeleton'
                    />
                    <Skeleton
                      variant='rect'
                      width={32}
                      height={32}
                      className='rectSkeleton'
                    />
                  </ListItem>
                  <ListItem className='bottomListItem'>
                    <Skeleton animation='wave' className='skeletonTextSecond' />
                  </ListItem>
                </List>
              </Container>
            </Container>
          )}
          <Container
            disableGutters={true}
            maxWidth={false}
            className='homeBottom'
          >
            {this.state.screenWidth < 620 && (
              <InfoIcon
                onClick={this.dialogHandleOpen}
                className='infoIconMainScreen'
              />
            )}
          </Container>
        </div>
        <BottomNav
          handleBtn={this.props.finishHandler}
          btnParam={
            window.location.pathname.includes("/fr")
              ? "Conclusion"
              : window.location.pathname.includes("/it")
              ? "Conclusione"
              : window.location.pathname.includes("/en")
              ? "Completion"
              : "Abschluss"
          }
          backBtnState={true}
          hideBbtnArror={true}
          backBtnText={
            window.location.pathname.includes("/fr")
              ? "Noté tous les trajets de toute la semaine?"
              : window.location.pathname.includes("/it")
              ? "Registrato tutti i viaggi di tutta la settimana?"
              : window.location.pathname.includes("/en")
              ? "All trips recorded for the entire week?"
              : "Alle Reisen der gesamten Woche erfasst?"
          }
          backButtonHandler={this.props.backButtonHandler}
          btnText={
            window.location.pathname.includes("/fr")
              ? "Terminer l'enquête"
              : window.location.pathname.includes("/it")
              ? "Concludere sondaggio"
              : window.location.pathname.includes("/en")
              ? "Complete survey"
              : "Umfrage Abschliessen"
          }
          flexJustify={"flex-end"}
        />
      </React.Fragment>
    );
  }
}

export default Dashboard;
