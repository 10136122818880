import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Skeleton from "@material-ui/lab/Skeleton";
import * as moment from "moment";
// import Card from "@material-ui/core/Card";
import ListSubheader from "@material-ui/core/ListSubheader";
// import CardContent from "@material-ui/core/CardContent";
// import CloseIcon from "@material-ui/icons/Close";
import Reason from "./Reason";
import Contest from "./Contest";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import "moment/locale/de";
import "./styles.css";
import BottomNav from "../../../Shared/BottomNav";
import Popup from "../../../Shared/Popup";
import DatesInterval from "../../../components/services/DatesInterval";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: null,
      tripsData: null,
      totalTrips: 0,
      datesRange: [],
      screen: null,
      reason: null,
      finishDialog: false,
      multipleTrips: [],
      loading: false,
      periodStart: this.props.state.userProfile.PeriodStart,
      periodEnd: this.props.state.userProfile.PeriodEnd,
      popupDisplay: false,
      popupNoTrips: {
        text: "",
      },
    };
  }

  componentDidMount() {
    //window.location.pathname.includes("/fr")
    //  ? moment.locale("fr")
    //  : window.location.pathname.includes("/it")
    //  ? moment.locale("it")
    //  : window.location.pathname.includes("/en")
    //  ? moment.locale("en")
    //  : moment.locale("de");
    this.handleUser();
    this.props.backButtonChecker();
  }

  handleUser = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/Journeys/Journeys`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("RESULTS====", result);
        if (result === null) {
          this.setState({
            loading: true,
            tripsData: null,
            totalTrips: 0,
            popupTrips: {
              text: window.location.pathname.includes("/fr") ? (
                <Typography variant='h5' className='finishDialogContent'>
                  Vous n'avez saisi aucun trajet en transports publics (train,
                  tram, bus, bateau, remontées mécaniques). Pensez aux petits
                  trajets et courses en ville et notez-les. Si vous n'avez
                  vraiment pas employé votre abonnement général du{" "}
                  {moment(this.props.state.userProfile.PeriodStart).format(
                    "DD.MM.YYYY"
                  )}{" "}
                  au{" "}
                  {moment(this.props.state.userProfile.PeriodEnd).format(
                    "DD.MM.YYYY"
                  )}{" "}
                  cliquez sur "Continuer".
                </Typography>
              ) : window.location.pathname.includes("/it") ? (
                <Typography variant='h5' className='finishDialogContent'>
                  Non ha registrato alcun viaggio effettuato con mezzi di 
                  trasporto pubblici come ferrovia, autobus o funivia. Pensi 
                  anche alle corse brevi e locali e le registri. Non ha utilizzato 
                  il suo abbonamento BÜGA dal{" "} 
                  {moment(this.props.state.userProfile.PeriodStart).format(
                    "DD.MM.YYYY"
                  )}{" "}
                  al{" "}
                  {moment(this.props.state.userProfile.PeriodEnd).format(
                    "DD.MM.YYYY"
                  )}{" "}
                  In tal caso clicchi su «Avanti».
                </Typography>
              ) : window.location.pathname.includes("/en") ? (
                <Typography variant='h5' className='finishDialogContent'>
                  You have not recorded any trips by public transport such as
                  train, tram, bus, boat or mountain railway. Please also
                  include and record short and local trips. If you have not used
                  your GA travelcard in the period from{" "}
                  {moment(this.props.state.userProfile.PeriodStart).format(
                    "DD.MM.YYYY"
                  )}{" "}
                  until{" "}
                  {moment(this.props.state.userProfile.PeriodEnd).format(
                    "DD.MM.YYYY"
                  )}{" "}
                  then press "continue".
                </Typography>
              ) : (
                <Typography variant='h5' className='finishDialogContent'>
                  Sie haben keine Reisen mit öffentlichen Verkehrsmitteln wie 
                  Bahn, Bus, oder Bergbahn erfasst. Denken Sie auch an kurze und 
                  lokale Reisen und erfassen Sie diese. Haben Sie Ihr BÜGA vom{" "}
                  {moment(this.props.state.userProfile.PeriodStart).format(
                    "DD.MM.YYYY"
                  )}{" "}
                  –{" "}
                  {moment(this.props.state.userProfile.PeriodEnd).format(
                    "DD.MM.YYYY"
                  )}{" "}
                  nicht genutzt? Dann drücken Sie auf "Weiter".
                </Typography>
              ),
            },
            popupDisplay: true,
          });
        } else {
          this.setState(
            {
              loading: true,
              tripsData: result,
              totalTrips: result.length,
            },
            () => {
              this.setState({
                popupTrips: {
                  text: window.location.pathname.includes("/fr") ? (
                    this.state.totalTrips ? (
                      <Typography
                        variant='body1'
                        className='finishDialogContent'
                      >
                        Avez-vous noté tous les trajets effectués en transports
                        publics (train, tram, bus, bateau, remontées mécaniques)
                        pendant la période donnée? N'oubliez pas les petits
                        trajets locaux. Une fois l'enquête clôturée, il ne sera
                        plus possible d'apporter des modifications.
                      </Typography>
                    ) : (
                      <Typography
                        variant='body1'
                        className='finishDialogContent'
                      >
                        Vous n'avez saisi aucun trajet en transports publics
                        (train, tram, bus, bateau, remontées mécaniques). Pensez
                        aux petits trajets et courses en ville et notez-les. Si
                        vous n'avez vraiment pas employé votre abonnement
                        général du{" "}
                        {moment(
                          this.props.state.userProfile.PeriodStart
                        ).format("DD.MM.YYYY")}{" "}
                        au{" "}
                        {moment(this.props.state.userProfile.PeriodEnd).format(
                          "DD.MM.YYYY"
                        )}{" "}
                        cliquez sur "Continuer".
                      </Typography>
                    )
                  ) : window.location.pathname.includes("/it") ? (
                    this.state.totalTrips ? (
                      <Typography
                        variant='body1'
                        className='finishDialogContent'
                      >
                        Ha registrato tutti i viaggi effettuati con mezzi di 
                        trasporto pubblici come ferrovia, autobus o funivia nel 
                        periodo indicato? Pensi anche alle corse brevi e locali. 
                        Dopo la conclusione non sono più ammesse modifiche.
                      </Typography>
                    ) : (
                      <Typography
                        variant='body1'
                        className='finishDialogContent'
                      >
                        Non ha registrato alcun viaggio effettuato con mezzi 
                        di trasporto pubblici come ferrovia, autobus o funivia. 
                        Pensi anche alle corse brevi e locali e le registri. Non 
                        ha utilizzato il suo abbonamento BÜGA dal{" "}
                        {moment(
                          this.props.state.userProfile.PeriodStart
                        ).format("DD.MM.YYYY")}{" "}
                        al{" "}
                        {moment(this.props.state.userProfile.PeriodEnd).format(
                          "DD.MM.YYYY"
                        )}{" "}
                        In tal caso clicchi su «Avanti».
                      </Typography>
                    )
                  ) : window.location.pathname.includes("/en") ? (
                    this.state.totalTrips ? (
                      <Typography
                        variant='body1'
                        className='finishDialogContent'
                      >
                        Have you entered ALL the journeys you made by public
                        transport, such as train, tram, bus, ship or cable car?
                        Don't forget to include any short and local journeys.
                        Once you've closed your diary, you won't be able to make
                        any changes.
                      </Typography>
                    ) : (
                      <Typography
                        variant='body1'
                        className='finishDialogContent'
                      >
                        You have not recorded any trips by public transport such
                        as train, tram, bus, boat or mountain railway. Please
                        also include and record short and local trips. If you
                        have not used your GA travelcard in the period from{" "}
                        {moment(
                          this.props.state.userProfile.PeriodStart
                        ).format("DD.MM.YYYY")}{" "}
                        until{" "}
                        {moment(this.props.state.userProfile.PeriodEnd).format(
                          "DD.MM.YYYY"
                        )}{" "}
                        then press "continue".
                      </Typography>
                    )
                  ) : this.state.totalTrips ? (
                    <Typography variant='body1' className='finishDialogContent'>
                      Haben Sie alle Reisen mit öffentlichen Verkehrsmitteln wie Bahn, 
                      Bus oder Seilbahn im angegebenen Zeitraum erfasst? Denken Sie dabei 
                      auch an kurze und lokale Reisen. Nach dem Abschluss sind keine Änderungen 
                      mehr möglich.
                    </Typography>
                  ) : (
                    <Typography variant='body1' className='finishDialogContent'>
                      Sie haben keine Reisen mit öffentlichen Verkehrsmitteln wie 
                      Bahn, Bus, oder Bergbahn erfasst. Denken Sie auch an kurze und 
                      lokale Reisen und erfassen Sie diese. Haben Sie Ihr BÜGA vom{" "}
                      {moment(this.props.state.userProfile.PeriodStart).format(
                        "DD.MM.YYYY"
                      )}{" "}
                      –{" "}
                      {moment(this.props.state.userProfile.PeriodEnd).format(
                        "DD.MM.YYYY"
                      )}{" "}
                      nicht genutzt? Dann drücken Sie auf "Weiter".
                    </Typography>
                  ),
                },
                popupDisplay: true,
              });
            }
          );
        }
        let dateInterval = DatesInterval(
          new Date(this.state.periodStart),
          new Date(this.state.periodEnd)
        );
        //Get only the unique dates
        // let trips = result;
        // if (trips !== null) {
        //   trips = trips
        //     .map((trip) => trip.JourneyDate)
        //     .filter(
        //       (JourneyDateString, index, array) =>
        //         array.indexOf(JourneyDateString) === index
        //     );
        //   trips = trips.sort(
        //     (a, b) => new Date(a).getTime() - new Date(b).getTime()
        //   );
        // }
        this.setState({ multipleTrips: [...dateInterval] }, () => {});
      })
      .catch((error) => console.log("error", error));
  };

  dialogHandler = (param) => {
    this.setState({
      finishDialog: param,
      screen: null,
    });
  };

  dialogYes = () => {
    this.props.contestPage();
    this.setState({
      screen: "Contest",
    });
  };
  // Go to reason screen
  goToReason = () => {
    this.setState({
      screen: "Reason",
    });
  };

  preFinish = () => {
    if (this.state.totalTrips > 0) {
      // console.log('FINISH JOURNEY');
      this.dialogHandler(true);
    } else {
      // console.log('ASK QUESTIONS');
      this.goToReason();
    }
  };

  reasonHandler = (e) => {
    // console.log("E TARGET NAME:  ", e.target.name);
    this.setState({ [e.target.name]: e.target.value });
  };

  finishJourneyCompetition = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/Periodapi/Close`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "lang=de; __RequestVerificationToken=LQVpuQYP15-heStJ9FHPW8zxkP_PQeu0RmMSVvjwi68WtlkoPGwUZRUbwvZs7QAgOxQ-fx9R2YPgBzxkNhDvts7TEoyiUvZy06t3l8TgJJ81"
    );

    var raw = JSON.stringify({
      Journeys: this.state.tripsData,
      Notes: null,
      Reason: this.state.reason,
      ReasonFreetext: "Andere",
      HeaderTitle: null,
      CopyOverride: null,
      PeriodStart: this.props.state.userProfile.PeriodStart,
      PeriodEnd: this.props.state.userProfile.PeriodEnd,
      SubmissionDeadline: this.props.state.userProfile.SubmissionDeadline,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.text())
      .catch((error) => console.log("error", error));
  };

  finishJourney = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/Periodapi/Close`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "lang=de; __RequestVerificationToken=LQVpuQYP15-heStJ9FHPW8zxkP_PQeu0RmMSVvjwi68WtlkoPGwUZRUbwvZs7QAgOxQ-fx9R2YPgBzxkNhDvts7TEoyiUvZy06t3l8TgJJ81"
    );

    var raw = JSON.stringify({
      Journeys: this.state.tripsData,
      Notes: null,
      Reason: this.state.reason,
      ReasonFreetext: "Andere",
      HeaderTitle: null,
      CopyOverride: null,
      PeriodStart: this.props.state.userProfile.PeriodStart,
      PeriodEnd: this.props.state.userProfile.PeriodEnd,
      SubmissionDeadline: this.props.state.userProfile.SubmissionDeadline,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.text())
      .catch((error) => console.log("error", error));

    this.finalizeJourney();
  };

  finalizeJourney = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/Periodapi/Finalize`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "lang=de; __RequestVerificationToken=LQVpuQYP15-heStJ9FHPW8zxkP_PQeu0RmMSVvjwi68WtlkoPGwUZRUbwvZs7QAgOxQ-fx9R2YPgBzxkNhDvts7TEoyiUvZy06t3l8TgJJ81"
    );

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.text())
      .catch((error) => console.log("error", error));

    localStorage.clear();
    this.props.history.push("/vielendank");
  };
  //Toggle popup
  togglePopup = (e) => {
    if (e) {
      if (e.target === e.currentTarget) {
        this.setState({ popupDisplay: !this.state.popupDisplay });
        return;
      }
    }
    this.setState({ popupDisplay: !this.state.popupDisplay });
  };

  //Handle date styling
  styleDate = (string) => {
    let strArr = string.split("-");
    strArr = strArr.map((el, index) => {
      let tempEl = el.trim();

      if (index === 0) {
        return (
          <span key={index} className='bold-text'>
            {tempEl}
          </span>
        );
      } else if (index === strArr.length - 1) {
        return (
          <span key={index} className='bold-text'>
            {tempEl}
          </span>
        );
      }
      return <span key={index}>{tempEl}</span>;
    });
    let styledString = [];
    for (let i = 0; i < strArr.length; i++) {
      styledString.push(strArr[i]);
      if (i < strArr.length - 1) {
        styledString.push(<span key={i + 100}> - </span>);
      }
    }
    // console.log(styledString);
    return styledString;
  };

    render() {
        window.location.pathname.includes("/fr")
            ? moment.locale("fr")
            : window.location.pathname.includes("/it")
                ? moment.locale("it")
                : window.location.pathname.includes("/en")
                    ? moment.locale("en")
                    : moment.locale("de");
    //moment.locale("de");
    // var date = moment(this.props.state.userProfile.PeriodEnd);
    // var dateStart = moment(this.props.state.userProfile.PeriodStart);
    // var difference = dateStart.diff(date, "days");
    // // console.log("Difference of period=====", difference);
    // var diffDays = [];

    // while (difference < 1) {
    //   diffDays.push(difference);
    //   difference++;
    // }
    return (
      <React.Fragment>
        <CssBaseline />
        <Popup
          display={this.state.popupDisplay}
          content={this.state.popupTrips}
          hidePopup={this.togglePopup}
        />
        {this.state.screen === "Reason" ? (
          <Reason
            dialogHandler={this.dialogHandler}
            reasonHandler={this.reasonHandler}
            state={this.state}
            finishJourney={this.finishJourney}
            backButtonHandler={this.props.backButtonHandler}
            backBtnState={this.props.state.back}
          />
        ) : this.state.screen === "Contest" ? (
          <Contest
            state={this.state}
            finishJourneyCompetition={this.finishJourneyCompetition}
            finalizeJourney={this.finalizeJourney}
            backButtonHandler={this.props.backButtonHandler}
            backBtnState={this.props.state.back}
          />
        ) : (
          <>
            <div className='_container'>
              <Container
                maxWidth={false}
                className='datumScreen'
                disableGutters={true}
                style={{ minHeight: "90vh" }}
              >
                {window.location.pathname.includes("/fr") ? (
                  <Dialog
                    open={this.state.finishDialog}
                    aria-labelledby='responsive-dialog-title'
                  >
                    <DialogTitle id='responsive-dialog-title'>
                      Souhaitez-vous participer au tirage au sort?
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Parmi les participants, nous tirons au sort chaque mois
                        10 bons Reka Rail d’une valeur de 50 francs. Le tirage
                        au sort annuel a pour prix principal un AG de 1re classe
                        ou un bon de votre choix d’une valeur de 6300 francs.
                        Pour que vous participiez au tirage au sort, nous avons
                        besoin de vos coordonnées.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => this.finishJourney()}
                        autoFocus
                        color='primary'
                      >
                        Non merci
                      </Button>
                      <Button
                        onClick={() => this.dialogYes()}
                        color='primary'
                        autoFocus
                      >
                        Je souhaite participer
                      </Button>
                    </DialogActions>
                  </Dialog>
                ) : window.location.pathname.includes("/it") ? (
                  <Dialog
                    open={this.state.finishDialog}
                    aria-labelledby='responsive-dialog-title'
                  >
                    <DialogTitle id='responsive-dialog-title'>
                      Desidera partecipare al nostro concorso?
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                      Ogni mese sorteggiamo tra i partecipanti una carta 
                      regalo TP del valore di 200 franchi e come premio principale 
                      una una carta regalo TP del valore di 2000 franchi. 
                      A tale scopo abbiamo bisogno dei suoi dati di contatto.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => this.finishJourney()}
                        autoFocus
                        color='primary'
                      >
                        No, grazie
                      </Button>
                      <Button
                        onClick={() => this.dialogYes()}
                        color='primary'
                        autoFocus
                      >
                        Desidero partecipare
                      </Button>
                    </DialogActions>
                  </Dialog>
                ) : window.location.pathname.includes("/en") ? (
                  <Dialog
                    open={this.state.finishDialog}
                    aria-labelledby='responsive-dialog-title'
                  >
                    <DialogTitle id='responsive-dialog-title'>
                      Would you like to enter our prize draw?
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Participants will be entered into a monthly draw for 10
                        Reka-Check vouchers worth CHF 50 each and, as the main
                        prize, a 1st class GA travelcard or a voucher of your
                        choice worth CHF 6300. To participate, we need your
                        contact details.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => this.finishJourney()}
                        autoFocus
                        color='primary'
                      >
                        No, thank you
                      </Button>
                      <Button
                        onClick={() => this.dialogYes()}
                        color='primary'
                        autoFocus
                      >
                        Yes, I'd like to enter
                      </Button>
                    </DialogActions>
                  </Dialog>
                ) : (
                  <Dialog
                    open={this.state.finishDialog}
                    aria-labelledby='responsive-dialog-title'
                  >
                    <DialogTitle id='responsive-dialog-title'>
                      {"Möchten Sie an unserem Wettbewerb teilnehmen?"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                      Unter den Teilnehmenden verlosen wir monatlich eine 
                      öV-Geschenkkarte im Wert von 200 Franken und als 
                      Hauptpreis eine öV-Geschenkkarte im Wert von 2000 
                      Franken. Für die Teilnahme benötigen wir Ihre Kontaktdaten.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => this.finishJourney()}
                        autoFocus
                        color='primary'
                      >
                        Nein danke
                      </Button>
                      <Button
                        onClick={() => this.dialogYes()}
                        color='primary'
                        autoFocus
                      >
                        Ich möchte teilnehmen
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}

                <Container maxWidth={false} disableGutters={true}>
                  {this.state.loading ? (
                    this.state.tripsData === null ? (
                      <div>
                        {/* <Typography component="div" variant="h6" className="welcomeBanner">
                    Bitte erfassen Sie ihre Reisen.
                  </Typography> */}
                        <Container maxWidth='false' disableGutters='true'>
                          <Container
                            maxWidth={false}
                            disableGutters={true}
                            className='finishTripsList'
                          >
                            {window.location.pathname.includes("/fr") ? (
                              <List
                                subheader={
                                  <ListSubheader>
                                    Aucun trajet enregistré n'a été trouvé.
                                  </ListSubheader>
                                }
                                // className="listItem"
                              ></List>
                            ) : window.location.pathname.includes("/it") ? (
                              <List
                                subheader={
                                  <ListSubheader>
                                    Nessun viaggio registrato trovato.
                                  </ListSubheader>
                                }
                                // className="listItem"
                              ></List>
                            ) : window.location.pathname.includes("/en") ? (
                              <List
                                subheader={
                                  <ListSubheader>
                                    No recorded trips found.
                                  </ListSubheader>
                                }
                                // className="listItem"
                              ></List>
                            ) : (
                              <List
                                subheader={
                                  <ListSubheader>
                                    Keine erfassten Reisen gefunden.
                                  </ListSubheader>
                                }
                                // className="listItem"
                              ></List>
                            )}
                          </Container>
                        </Container>
                      </div>
                    ) : this.state.tripsData.length > 0 ? (
                      <Container maxWidth={false} disableGutters={true}>
                        <Container
                          maxWidth={false}
                          disableGutters={true}
                          className='finishTripsList'
                        >
                          {this.state.multipleTrips.map((eachDate, index) => (
                            <List
                              key={eachDate}
                              subheader={
                                <ListSubheader
                                  style={{ backgroundColor: "#f2f2f2" }}
                                >
                                  {moment(eachDate).format("dddd, D.MM.YYYY ")}
                                </ListSubheader>
                              }
                              // className="listItem"
                            >
                              {this.state.tripsData.map((trip, index) =>
                                new Date(trip.JourneyDate).getTime() ===
                                eachDate.getTime() ? (
                                  <ListItemText
                                    key={index}
                                    primary={this.styleDate(
                                      trip.JourneySummary
                                    )}
                                    className='listItemFinishJourney'
                                  />
                                ) : null
                              )}
                            </List>
                          ))}
                        </Container>
                      </Container>
                    ) : (
                      <Container
                        className='finishTripsList'
                        maxWidth={false}
                        disableGutters={true}
                      >
                        <Container disableGutters={true}>
                          <List>
                            <ListItem className='topListItem'>
                              <Skeleton
                                animation='wave'
                                className='skeletonText'
                              />
                              <Typography className='spacer'></Typography>
                              <Skeleton
                                variant='rect'
                                width={32}
                                height={32}
                                className='rectSkeleton'
                              />
                              <Skeleton
                                variant='rect'
                                width={32}
                                height={32}
                                className='rectSkeleton'
                              />
                              <Skeleton
                                variant='rect'
                                width={32}
                                height={32}
                                className='rectSkeleton'
                              />
                            </ListItem>
                            <ListItem className='bottomListItem'>
                              <Skeleton
                                animation='wave'
                                className='skeletonTextSecond'
                              />
                            </ListItem>
                          </List>
                          <List>
                            <ListItem className='topListItem'>
                              <Skeleton
                                animation='wave'
                                className='skeletonText'
                              />
                              <Typography className='spacer'></Typography>
                              <Skeleton
                                variant='rect'
                                width={32}
                                height={32}
                                className='rectSkeleton'
                              />
                              <Skeleton
                                variant='rect'
                                width={32}
                                height={32}
                                className='rectSkeleton'
                              />
                              <Skeleton
                                variant='rect'
                                width={32}
                                height={32}
                                className='rectSkeleton'
                              />
                            </ListItem>
                            <ListItem className='bottomListItem'>
                              <Skeleton
                                animation='wave'
                                className='skeletonTextSecond'
                              />
                            </ListItem>
                          </List>
                          <List>
                            <ListItem className='topListItem'>
                              <Skeleton
                                animation='wave'
                                className='skeletonText'
                              />
                              <Typography className='spacer'></Typography>
                              <Skeleton
                                variant='rect'
                                width={32}
                                height={32}
                                className='rectSkeleton'
                              />
                              <Skeleton
                                variant='rect'
                                width={32}
                                height={32}
                                className='rectSkeleton'
                              />
                              <Skeleton
                                variant='rect'
                                width={32}
                                height={32}
                                className='rectSkeleton'
                              />
                            </ListItem>
                            <ListItem className='bottomListItem'>
                              <Skeleton
                                animation='wave'
                                className='skeletonTextSecond'
                              />
                            </ListItem>
                          </List>
                        </Container>
                      </Container>
                    )
                  ) : (
                    <Container maxWidth={false} disableGutters={true}>
                      <Container
                        maxWidth={false}
                        disableGutters={true}
                        className='finishTripsList'
                      >
                        <List
                          subheader={<ListSubheader>Loading</ListSubheader>}
                          // className="listItem"
                        ></List>
                      </Container>
                    </Container>
                  )}
                  {/* <BottomNavigation
                onClick={() => this.preFinish()}
                className="homeCompleteButton connectingContinueButton"
                style={{ backgroundColor: "#EEEEEE" }}
              >
                {window.location.pathname.includes("/fr") ? (
                  <Typography
                    variant="body1"
                    className="connectingContinueButtonContent"
                  >
                    Continuer
                  </Typography>
                ) : window.location.pathname.includes("/it") ? (
                  <Typography
                    variant="body1"
                    className="connectingContinueButtonContent"
                  >
                    Avanti
                  </Typography>
                ) : (
                  <Typography
                    variant="body1"
                    className="connectingContinueButtonContent"
                  >
                    Weiter
                  </Typography>
                )}
                <ArrowForwardIosSharpIcon />
              </BottomNavigation> */}
                </Container>
              </Container>
            </div>
            <BottomNav
              backBtnText={
                window.location.pathname.includes("/fr")
                  ? "RETOUR"
                  : window.location.pathname.includes("/it")
                  ? "INDIETRO"
                  : window.location.pathname.includes("/en")
                  ? "BACK"
                  : "ZURÜCK"
              }
              handleBtn={this.preFinish}
              btnText={
                window.location.pathname.includes("/fr")
                  ? "Continuer"
                  : window.location.pathname.includes("/it")
                  ? "Avanti"
                  : window.location.pathname.includes("/en")
                  ? "Continue"
                  : "Weiter"
              }
              backBtnState={this.props.state.back}
              backButtonHandler={this.props.backButtonHandler}
            />
          </>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Main);
