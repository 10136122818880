import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
// import { DatePicker } from "@material-ui/pickers";
import BottomNav from "../../../Shared/BottomNav";
import TextField from "@material-ui/core/TextField";
import train from "../../../assets/icons/train.svg";
import tram from "../../../assets/icons/tram.svg";
import bus from "../../../assets/icons/bus.svg";
import boat from "../../../assets/icons/boat.svg";
import andere from "../../../assets/icons/andere.svg";
import RemoveCircleSharpIcon from "@material-ui/icons/RemoveCircleSharp";
import AddCircleSharpIcon from "@material-ui/icons/AddCircleSharp";
import axios from "axios";
import InputAdornment from "@material-ui/core/InputAdornment";
import * as moment from "moment";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// import PickerToolbar from "@material-ui/pickers/_shared/PickerToolbar";
// import ToolbarButton from "@material-ui/pickers/_shared/ToolbarButton";
import { makeStyles } from "@material-ui/core";
import "moment/locale/de";
import "./styles.css";
import DateSelect from "../DateSelect";

export const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
});

// const CustomToolbar = function (props) {
//   const { date, isLandscape, openView, setOpenView, title } = props;

//   const handleChangeViewClick = (view) => (e) => {
//     setOpenView(view);
//   };

//   const classes = useStyles();

//   return (
//     <PickerToolbar
//       className={classes.toolbar}
//       title={title}
//       isLandscape={isLandscape}
//     >
//       <ToolbarButton
//         onClick={handleChangeViewClick("year")}
//         variant="h6"
//         label={date.format("YYYY")}
//         selected={openView === "year"}
//       />
//       <ToolbarButton
//         onClick={handleChangeViewClick("Sélectionner la date du trajet")}
//         variant="h4"
//         selected={openView === "Sélectionner la date du trajet"}
//         label={date.format("dddd, D. MMM")}
//       />
//     </PickerToolbar>
//   );
// };

class Main extends Component {
  constructor(props) {
    super(props);
    window.location.pathname.includes("/fr")
      ? (this.state = {
          fromBahn: "",
          fromTram: "",
          fromBus: "",
          fromSchiff: "",
          fromAndere: "",
          toBahn: "",
          toTram: "",
          toBus: "",
          toSchiff: "",
          toAndere: "",
          stations: [],
          viaStations: [],
          endStations: [],
          localStops: [],
          loading: true,
          errors: false,
          dialogError: "Veuillez sélectionner le moyen de transport utilisé",
          fade: "out",
          dialog: "firstTimeOut",
          updateDateController: false,
        })
      : window.location.pathname.includes("/fr")
      ? (this.state = {
          fromBahn: "",
          fromTram: "",
          fromBus: "",
          fromSchiff: "",
          fromAndere: "",
          toBahn: "",
          toTram: "",
          toBus: "",
          toSchiff: "",
          toAndere: "",
          stations: [],
          viaStations: [],
          endStations: [],
          localStops: [],
          loading: true,
          errors: false,
          dialogError: "Selezioni il mezzo di trasporto utilizzato",
          fade: "out",
          dialog: "firstTimeOut",
          updateDateController: false,
        })
      : window.location.pathname.includes("/en")
      ? (this.state = {
          fromBahn: "",
          fromTram: "",
          fromBus: "",
          fromSchiff: "",
          fromAndere: "",
          toBahn: "",
          toTram: "",
          toBus: "",
          toSchiff: "",
          toAndere: "",
          stations: [],
          viaStations: [],
          endStations: [],
          localStops: [],
          loading: true,
          errors: false,
          dialogError: "Please select all types of transport",
          fade: "out",
          dialog: "firstTimeOut",
          updateDateController: false,
        })
      : (this.state = {
          fromBahn: "",
          fromTram: "",
          fromBus: "",
          fromSchiff: "",
          fromAndere: "",
          toBahn: "",
          toTram: "",
          toBus: "",
          toSchiff: "",
          toAndere: "",
          stations: [],
          viaStations: [],
          endStations: [],
          localStops: [],
          loading: true,
          errors: false,
          dialogError: "Bitte wählen Sie das benutzte Transportmittel aus",
          fade: "out",
          dialog: "firstTimeOut",
          updateDateController: false,
        });

    this.searchHandler = this.searchHandler.bind(this);
    this.endSearchHandler = this.endSearchHandler.bind(this);
    this.viaSearchHandler = this.viaSearchHandler.bind(this);
    this.onChangeDoubleFunVia = this.onChangeDoubleFunVia.bind(this);
  }

  componentDidMount() {
    this.props.backButtonChecker();
    this.currentStationHandler();
    // this.extraStationsTravelLoad();
    this.saveExtraStopsLocal();
    // document.getElementById("datePick").click();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.state.ExtraStops !== this.props.state.ExtraStops) {
      this.saveExtraStopsLocal();
    }
  }
  updateDateController = () => {
    if (this.state.updateDateController === false) {
      this.setState({ updateDateController: true });
    } else {
      this.setState({ updateDateController: false });
    }
  };
  saveExtraStopsLocal = () => {
    let tempStops = [];
    tempStops = this.props.state.ExtraStops.map((stop, index) => {
      return { id: index, name: stop.ViaStationName, type: stop.MeansOfTravel };
    });
    this.setState(
      {
        localStops: [...tempStops],
      },
      () => {
        this.setState({ loading: false });
      }
    );
    // this.props.state.ExtraStops.forEach((via, index) => {
    //   this.setState({
    //     [index + "-name"]: via.ViaStationName,
    //   });
    // });
  };

  errorDialog = () => {
    window.scrollTo(0, 0);
    this.setState({
      fade: "in",
    });

    setTimeout(() => {
      this.setState({
        fade: "out",
      });
    }, 2500);
  };

  errorChecker = () => {
    let check = false;
    this.props.state.ExtraStops.forEach((via) => {
      if (via.MeansOfTravel === null || via.ViaStationName === null) {
        check = true;
        return;
      }
    });

    this.setState(
      {
        errors: check,
      },
      () => {
        if (!this.state.errors) {
          this.props.putJourney();
          return;
        }
        this.errorDialog();
      }
    );
  };

  onChangeDoubleFun = (e) => {
    this.searchHandler(e);
    this.props.onChangeHandler(e);
  };

  onChangeDoubleFunVia = (e) => {
    let newArr = [];
    newArr = this.state.localStops.map((el, index) => {
      // eslint-disable-next-line
      if (e.target.name == index) {
        return {
          ...el,
          name: e.target.value,
          searchedName: e.target.value,
        };
      }
      return el;
    });
    this.setState({
      localStops: [...newArr],
    });
    this.viaSearchHandler(e);
    this.props.onChangeHandler(e);
  };

  onChangeDoubleFunEnd = (e) => {
    this.endSearchHandler(e);
    this.props.onChangeHandler(e);
  };

  searchHandler(event) {
    let proxyURL = process.env.REACT_APP_PROXY_URL;

    axios
      .get(
        `${proxyURL}${process.env.REACT_APP_BASE_URL}/api/Station/GetStations`,
        {
          headers: { Authorization: window.localStorage.AccessToken },
          params: { query: event.target.value },
        }
      )
      // .then((response) => {
      //   this.setState({ stations: response.data });
      // })
      
      .then((response) => {
        let newStations = response.data;

        if (
          newStations === undefined ||
          newStations.toString().trim().length === 0
        ) {
          newStations = [];
        }

        this.setState({
          stations: newStations,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  viaSearchHandler(event) {
    event.persist();
    let proxyURL = process.env.REACT_APP_PROXY_URL;

    axios
      .get(
        `${proxyURL}${process.env.REACT_APP_BASE_URL}/api/Station/GetStations`,
        {
          headers: { Authorization: window.localStorage.AccessToken },
          params: { query: event.target.value },
        }
      )
      .then((response) => {
        this.setState({
          [event.target.name + "-stations-list"]: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  endSearchHandler(event) {
    let proxyURL = process.env.REACT_APP_PROXY_URL;

    axios
      .get(
        `${proxyURL}${process.env.REACT_APP_BASE_URL}/api/Station/GetStations`,
        {
          headers: { Authorization: window.localStorage.AccessToken },
          params: { query: event.target.value },
        }
      )
      .then((response) => {
        this.setState({ endStations: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  resetStartSearchResultsHandler = (n, c) => {
    this.setState({
      stations: [],
    });
    this.props.handleStartPointUpdate(n, c);
  };

  resetViaSearchResultsHandler = (index, id, c, nc, nn, mt) => {
    this.setState({
      [index + "-stations-list"]: [],
      // [index + "-name"]: nn,
    });
    //this.props.handleStartPointUpdate(n, c);
    //this.props.removeConnectingPoint(n);
    this.props.handleViaStationUpdate(index, id, nc, nn, mt);
  };

  resetEndSearchResultsHandler = (n, c) => {
    this.setState({
      endStations: [],
    });
    this.props.handleEndPointUpdate(n, c);
  };

  currentStationHandler = () => {
    if (this.props.state.startTravel === "Bahn") {
      this.setState({
        fromBahn: "active",
      });
    }
    if (this.props.state.startTravel === "Tram") {
      this.setState({
        fromTram: "active",
      });
    }
    if (this.props.state.startTravel === "Bus") {
      this.setState({
        fromBus: "active",
      });
    }
    if (this.props.state.startTravel === "Schiff") {
      this.setState({
        fromSchiff: "active",
      });
    }
    if (this.props.state.startTravel === "Andere") {
      this.setState({
        fromAndere: "active",
      });
    }
  };

  extraStationsTravelLoad = () => {
    this.props.state.ExtraStops.map((viaStation) =>
      this.setState({
        [viaStation.ViaStationName + "-meansTransport"]:
          viaStation.MeansOfTravel,
      })
    );
  };

  fromBahnHandler = (e) => {
    this.props.handleStartTravel(e);
    this.setState({
      fromBahn: "active",
      fromTram: "",
      fromBus: "",
      fromSchiff: "",
      fromAndere: "",
    });
  };

  fromTramHandler = (e) => {
    this.props.handleStartTravel(e);
    this.setState({
      fromBahn: "",
      fromTram: "active",
      fromBus: "",
      fromSchiff: "",
      fromAndere: "",
    });
  };

  fromBusHandler = (e) => {
    this.props.handleStartTravel(e);
    this.setState({
      fromBahn: "",
      fromTram: "",
      fromBus: "active",
      fromSchiff: "",
      fromAndere: "",
    });
  };

  fromSchiffHandler = (e) => {
    this.props.handleStartTravel(e);
    this.setState({
      fromBahn: "",
      fromTram: "",
      fromBus: "",
      fromSchiff: "active",
      fromAndere: "",
    });
  };

  fromAndereHandler = (e) => {
    this.props.handleStartTravel(e);
    this.setState({
      fromBahn: "",
      fromTram: "",
      fromBus: "",
      fromSchiff: "",
      fromAndere: "active",
    });
  };

  viaBahn = (index, id, code, name) => {
    this.props.handleViaStationTransportTypeUpdate(
      index,
      id,
      code,
      name,
      "Bahn"
    );
    // setTimeout(() => {
    //   this.setState({
    //     [id]: "Bahn",
    //     [code + "-name"]: name,
    //   });
    // }, 20);
  };

  viaTram = (index, id, code, name) => {
    this.props.handleViaStationTransportTypeUpdate(
      index,
      id,
      code,
      name,
      "Tram"
    );
    // setTimeout(() => {
    //   this.setState({
    //     [id]: "Tram",
    //     [code + "Name"]: name,
    //   });
    // }, 20);
  };

  viaBus = (index, id, code, name) => {
    this.props.handleViaStationTransportTypeUpdate(
      index,
      id,
      code,
      name,
      "Bus"
    );
    // setTimeout(() => {
    //   this.setState({
    //     [id]: "Bus",
    //     [code + "Name"]: name,
    //   });
    // }, 20);
  };

  viaSchiff = (index, id, code, name) => {
    this.props.handleViaStationTransportTypeUpdate(
      index,
      id,
      code,
      name,
      "Schiff"
    );
    // setTimeout(() => {
    //   this.setState({
    //     [id]: "Schiff",
    //     [code + "Name"]: name,
    //   });
    // }, 20);
  };

  viaAndere = (index, id, code, name) => {
    this.props.handleViaStationTransportTypeUpdate(
      index,
      id,
      code,
      name,
      "Andere"
    );
    // setTimeout(() => {
    //   this.setState({
    //     [id]: "Andere",
    //     [code + "Name"]: name,
    //   });
    // }, 20);
  };
  closeDateSelection = () => {
    this.setState({ updateDateController: false }, () => {});
  };

  //Remove station from local state
  removeStationHandler = (index) => {
    this.setState({ loading: true });
    // let newState = this.state.forEach((el) => el);
    // console.log("NEW STATE: ", newState);
  };

  render() {
    // eslint-disable-next-line
    {
      window.location.pathname.includes("/fr")
        ? moment.locale("fr")
        : window.location.pathname.includes("/it")
        ? moment.locale("it")
        : window.location.pathname.includes("/en")
        ? moment.locale("en")
        : moment.locale("de");
    }
    return (
      <React.Fragment>
        <CssBaseline />
        <Container
          maxWidth="false"
          className="datumScreen"
          disableGutters="true"
        >
          <Card
            raised="true"
            className={"errorDialog transportDialog fade-" + this.state.fade}
          >
            <CardContent className="errorContent">
              <Typography align="center">{this.state.dialogError}</Typography>
            </CardContent>
          </Card>
          <Container>
            <Button
              variant="contained"
              className="selectedDateButton"
              disableElevation
              size="large"
              fullWidth
              onClick={() => this.updateDateController()}
            >
              {moment(this.props.state.date).format("dddd, D. MMM YYYY")}
            </Button>
            {this.state.updateDateController ? (
              <DateSelect
                handleDateUpdate={this.props.handleDateUpdate}
                closeDateSelection={this.closeDateSelection}
              />
            ) : null}
            <TextField
              autoComplete="off"
              onChange={this.onChangeDoubleFun}
              name="startPointName"
              className="updateFields"
              fullWidth
              id="outlined-helperText"
              label={
                window.location.pathname.includes("/fr")
                  ? "Indiquer l'arrêt de départ"
                  : window.location.pathname.includes("/it")
                  ? "Immettere luogo di partenza"
                  : window.location.pathname.includes("/en")
                  ? "Departure point"
                  : "Startort eingeben"
              }
              value={this.props.state.startPointName}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AddCircleSharpIcon
                      onClick={() => {
                        this.props.handleNewViaStationUpdate(0, -1);
                        this.setState({ loading: true });
                      }}
                      className="addViaStationUJ"
                    />
                  </InputAdornment>
                ),
              }}
            />
            <ul className="searchResults">
              {this.state.stations.map((stations) => (
                <li
                  onClick={() =>
                    this.resetStartSearchResultsHandler(
                      stations.Name,
                      stations.Code
                    )
                  }
                >
                  {stations.Name}
                </li>
              ))}
            </ul>
            <Typography className="transportationType">
              <Typography className="transportTypeInner">
                <img
                  onClick={() => this.fromBahnHandler("Bahn")}
                  className={"transportTypeOverlay" + this.state.fromBahn}
                  src={train}
                  alt="Train"
                />
                <Typography>
                  {window.location.pathname.includes("/fr")
                    ? "Train"
                    : window.location.pathname.includes("/it")
                    ? "Treno"
                    : window.location.pathname.includes("/en")
                    ? "Rail"
                    : "Bahn"}
                </Typography>
              </Typography>
              <Typography className="transportTypeInner">
                <img
                  onClick={() => this.fromTramHandler("Tram")}
                  className={"transportTypeOverlay" + this.state.fromTram}
                  src={tram}
                  alt="Tram"
                />
                <Typography>
                  {window.location.pathname.includes("/fr")
                    ? "Tram, métro"
                    : window.location.pathname.includes("/it")
                    ? "Tram, metropolitana"
                    : window.location.pathname.includes("/en")
                    ? "Tram, metro"
                    : "Tram, Metro"}
                </Typography>
              </Typography>
              <Typography className="transportTypeInner">
                <img
                  onClick={() => this.fromBusHandler("Bus")}
                  className={"transportTypeOverlay" + this.state.fromBus}
                  src={bus}
                  alt="Bus"
                />
                <Typography>
                  {window.location.pathname.includes("/fr")
                    ? "Bus, car postal"
                    : window.location.pathname.includes("/it")
                    ? "Autobus, AutoPostale"
                    : window.location.pathname.includes("/en")
                    ? "Bus, PostBus"
                    : "Bus, Postauto"}
                </Typography>
              </Typography>
              <Typography className="transportTypeInner">
                <img
                  onClick={() => this.fromSchiffHandler("Schiff")}
                  className={"transportTypeOverlay" + this.state.fromSchiff}
                  src={boat}
                  alt="Boat"
                />
                <Typography>
                  {window.location.pathname.includes("/fr")
                    ? "Bateau"
                    : window.location.pathname.includes("/it")
                    ? "Battello"
                    : window.location.pathname.includes("/en")
                    ? "Boat"
                    : "Schiff"}
                </Typography>
              </Typography>
              <Typography className="transportTypeInner">
                <img
                  onClick={() => this.fromAndereHandler("Andere")}
                  className={
                    "anderetype transportTypeOverlay" + this.state.fromAndere
                  }
                  src={andere}
                  alt="andere"
                />
                <Typography>
                  {window.location.pathname.includes("/fr")
                    ? "Remontées mécaniques, autre"
                    : window.location.pathname.includes("/it")
                    ? "Funivia, altro"
                    : window.location.pathname.includes("/en")
                    ? "Cable car, other"
                    : "Bergbahn, Andere"}
                </Typography>
              </Typography>
            </Typography>
            {this.state.loading !== true ? (
              <div>
                {this.props.state.ExtraStops.map((viaStation, index) => (
                  <div>
                    <TextField
                      autoComplete="off"
                      // disabled={this.state[viaStation.ViaId] === undefined ? true : false}
                      onChange={this.onChangeDoubleFunVia}
                      name={index}
                      className="updateFields"
                      fullWidth
                      value={
                        this.state.localStops[index].name &&
                        this.state.localStops[index].name
                      }
                      placeholder={
                        window.location.pathname.includes("/fr")
                          ? "Veuillez saisir le nouveau nom de la station"
                          : window.location.pathname.includes("/it")
                          ? "Si prega di inserire il nuovo nome della stazione"
                          : window.location.pathname.includes("/en")
                          ? "Please enter new station name"
                          : "Bitte neue Haltestelle eingeben"
                      }
                      // placeholder={"Enter new station name"}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <AddCircleSharpIcon
                              onClick={() => {
                                this.props.handleNewViaStationUpdate(
                                  index + 1,
                                  -1
                                );
                                this.setState({ loading: true });
                              }}
                              className="addViaStationUJ"
                            />
                            <RemoveCircleSharpIcon
                              onClick={() => {
                                this.removeStationHandler(index);
                                this.props.removeConnectingPoint(index);
                              }}
                              className="removeViaStationUJ"
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ul className="searchResults">
                      {this.state.localStops[index]?.searchedName && (
                        <li
                          onClick={() =>
                            this.resetViaSearchResultsHandler(
                              index,
                              viaStation.ViaId,
                              viaStation.viaStationName,
                              -1,
                              this.state.localStops[index].searchedName,
                              this.state.localStops[index].type
                            )
                          }
                        >
                          {this.state.localStops[index].searchedName}
                        </li>
                      )}
                      {this.state[index + "-stations-list"] === undefined
                        ? null
                        : this.state[index + "-stations-list"].map(
                            (stations) => (
                              <li
                                onClick={() =>
                                  this.resetViaSearchResultsHandler(
                                    index,
                                    viaStation.ViaId,
                                    viaStation.viaStationName,
                                    stations.Code,
                                    stations.Name,
                                    null
                                  )
                                }
                              >
                                {stations.Name}
                              </li>
                            )
                          )}
                    </ul>
                    {viaStation.MeansOfTravel === "Bahn" ? (
                      <Typography className="transportationType">
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaBahn(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlayActive"}
                            src={train}
                            alt="Train"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Train"
                              : window.location.pathname.includes("/it")
                              ? "Treno"
                              : window.location.pathname.includes("/en")
                              ? "Rail"
                              : "Bahn"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaTram(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlay"}
                            src={tram}
                            alt="Tram"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Tram, métro"
                              : window.location.pathname.includes("/it")
                              ? "Tram, metropolitana"
                              : window.location.pathname.includes("/en")
                              ? "Tram, metro"
                              : "Tram, Metro"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaBus(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlay"}
                            src={bus}
                            alt="Bus"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Bus, car postal"
                              : window.location.pathname.includes("/it")
                              ? "Autobus, AutoPostale"
                              : window.location.pathname.includes("/en")
                              ? "Bus, PostBus"
                              : "Bus, Postauto"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaSchiff(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlay"}
                            src={boat}
                            alt="Boat"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Bateau"
                              : window.location.pathname.includes("/it")
                              ? "Battello"
                              : window.location.pathname.includes("/en")
                              ? "Boat"
                              : "Schiff"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaAndere(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"anderetype transportTypeOverlay"}
                            src={andere}
                            alt="andere"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Remontées mécaniques, autre"
                              : window.location.pathname.includes("/it")
                              ? "Funivia, altro"
                              : window.location.pathname.includes("/en")
                              ? "Cable car, other"
                              : "Bergbahn, Andere"}
                          </Typography>
                        </Typography>
                      </Typography>
                    ) : viaStation.MeansOfTravel === "Tram" ? (
                      <Typography className="transportationType">
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaBahn(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlay"}
                            src={train}
                            alt="Train"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Train"
                              : window.location.pathname.includes("/it")
                              ? "Treno"
                              : window.location.pathname.includes("/en")
                              ? "Rail"
                              : "Bahn"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaTram(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlayActive"}
                            src={tram}
                            alt="Tram"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Tram, métro"
                              : window.location.pathname.includes("/it")
                              ? "Tram, metropolitana"
                              : window.location.pathname.includes("/en")
                              ? "Tram, metro"
                              : "Tram, Metro"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaBus(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlay"}
                            src={bus}
                            alt="Bus"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Bus, car postal"
                              : window.location.pathname.includes("/it")
                              ? "Autobus, AutoPostale"
                              : window.location.pathname.includes("/en")
                              ? "Bus, PostBus"
                              : "Bus, Postauto"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaSchiff(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlay"}
                            src={boat}
                            alt="Boat"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Bateau"
                              : window.location.pathname.includes("/it")
                              ? "Battello"
                              : window.location.pathname.includes("/en")
                              ? "Boat"
                              : "Schiff"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaAndere(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"anderetype transportTypeOverlay"}
                            src={andere}
                            alt="andere"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Remontées mécaniques, autre"
                              : window.location.pathname.includes("/it")
                              ? "Funivia, altro"
                              : window.location.pathname.includes("/en")
                              ? "Cable car, other"
                              : "Bergbahn, Andere"}
                          </Typography>
                        </Typography>
                      </Typography>
                    ) : viaStation.MeansOfTravel === "Bus" ? (
                      <Typography className="transportationType">
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaBahn(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlay"}
                            src={train}
                            alt="Train"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Train"
                              : window.location.pathname.includes("/it")
                              ? "Treno"
                              : window.location.pathname.includes("/en")
                              ? "Rail"
                              : "Bahn"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaTram(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlay"}
                            src={tram}
                            alt="Tram"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Tram, métro"
                              : window.location.pathname.includes("/it")
                              ? "Tram, metropolitana"
                              : window.location.pathname.includes("/en")
                              ? "Tram, metro"
                              : "Tram, Metro"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaBus(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlayActive"}
                            src={bus}
                            alt="Bus"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Bus, car postal"
                              : window.location.pathname.includes("/it")
                              ? "Autobus, AutoPostale"
                              : window.location.pathname.includes("/en")
                              ? "Bus, PostBus"
                              : "Bus, Postauto"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaSchiff(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlay"}
                            src={boat}
                            alt="Boat"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Bateau"
                              : window.location.pathname.includes("/it")
                              ? "Battello"
                              : window.location.pathname.includes("/en")
                              ? "Boat"
                              : "Schiff"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaAndere(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"anderetype transportTypeOverlay"}
                            src={andere}
                            alt="andere"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Remontées mécaniques, autre"
                              : window.location.pathname.includes("/it")
                              ? "Funivia, altro"
                              : window.location.pathname.includes("/en")
                              ? "Cable car, other"
                              : "Bergbahn, Andere"}
                          </Typography>
                        </Typography>
                      </Typography>
                    ) : viaStation.MeansOfTravel === "Schiff" ? (
                      <Typography className="transportationType">
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaBahn(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlay"}
                            src={train}
                            alt="Train"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Train"
                              : window.location.pathname.includes("/it")
                              ? "Treno"
                              : window.location.pathname.includes("/en")
                              ? "Rail"
                              : "Bahn"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaTram(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlay"}
                            src={tram}
                            alt="Tram"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Tram, métro"
                              : window.location.pathname.includes("/it")
                              ? "Tram, metropolitana"
                              : window.location.pathname.includes("/en")
                              ? "Tram, metro"
                              : "Tram, Metro"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaBus(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlay"}
                            src={bus}
                            alt="Bus"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Bus, car postal"
                              : window.location.pathname.includes("/it")
                              ? "Autobus, AutoPostale"
                              : window.location.pathname.includes("/en")
                              ? "Bus, PostBus"
                              : "Bus, Postauto"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaSchiff(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlayActive"}
                            src={boat}
                            alt="Boat"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Bateau"
                              : window.location.pathname.includes("/it")
                              ? "Battello"
                              : window.location.pathname.includes("/en")
                              ? "Boat"
                              : "Schiff"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaAndere(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"anderetype transportTypeOverlay"}
                            src={andere}
                            alt="andere"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Remontées mécaniques, autre"
                              : window.location.pathname.includes("/it")
                              ? "Funivia, altro"
                              : window.location.pathname.includes("/en")
                              ? "Cable car, other"
                              : "Bergbahn, Andere"}
                          </Typography>
                        </Typography>
                      </Typography>
                    ) : viaStation.MeansOfTravel === "Andere" ? (
                      <Typography className="transportationType">
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaBahn(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlay"}
                            src={train}
                            alt="Train"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Train"
                              : window.location.pathname.includes("/it")
                              ? "Treno"
                              : window.location.pathname.includes("/en")
                              ? "Rail"
                              : "Bahn"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaTram(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlay"}
                            src={tram}
                            alt="Tram"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Tram, métro"
                              : window.location.pathname.includes("/it")
                              ? "Tram, metropolitana"
                              : window.location.pathname.includes("/en")
                              ? "Tram, metro"
                              : "Tram, Metro"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaBus(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlay"}
                            src={bus}
                            alt="Bus"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Bus, car postal"
                              : window.location.pathname.includes("/it")
                              ? "Autobus, AutoPostale"
                              : window.location.pathname.includes("/en")
                              ? "Bus, PostBus"
                              : "Bus, Postauto"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaSchiff(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlay"}
                            src={boat}
                            alt="Boat"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Bateau"
                              : window.location.pathname.includes("/it")
                              ? "Battello"
                              : window.location.pathname.includes("/en")
                              ? "Boat"
                              : "Schiff"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaAndere(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"anderetype transportTypeOverlayActive"}
                            src={andere}
                            alt="andere"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Remontées mécaniques, autre"
                              : window.location.pathname.includes("/it")
                              ? "Funivia, altro"
                              : window.location.pathname.includes("/en")
                              ? "Cable car, other"
                              : "Bergbahn, Andere"}
                          </Typography>
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography className="transportationType">
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaBahn(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlay"}
                            src={train}
                            alt="Train"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Train"
                              : window.location.pathname.includes("/it")
                              ? "Treno"
                              : window.location.pathname.includes("/en")
                              ? "Rail"
                              : "Bahn"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaTram(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlay"}
                            src={tram}
                            alt="Tram"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Tram, métro"
                              : window.location.pathname.includes("/it")
                              ? "Tram, metropolitana"
                              : window.location.pathname.includes("/en")
                              ? "Tram, metro"
                              : "Tram, Metro"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaBus(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlay"}
                            src={bus}
                            alt="Bus"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Bus, car postal"
                              : window.location.pathname.includes("/it")
                              ? "Autobus, AutoPostale"
                              : window.location.pathname.includes("/en")
                              ? "Bus, PostBus"
                              : "Bus, Postauto"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaSchiff(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"transportTypeOverlay"}
                            src={boat}
                            alt="Boat"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Bateau"
                              : window.location.pathname.includes("/it")
                              ? "Battello"
                              : window.location.pathname.includes("/en")
                              ? "Boat"
                              : "Schiff"}
                          </Typography>
                        </Typography>
                        <Typography className="transportTypeInner">
                          <img
                            onClick={() =>
                              this.viaAndere(
                                index,
                                viaStation.ViaId,
                                viaStation.ViaStationId,
                                this.state.localStops[index].name
                              )
                            }
                            className={"anderetype transportTypeOverlay"}
                            src={andere}
                            alt="andere"
                          />
                          <Typography>
                            {window.location.pathname.includes("/fr")
                              ? "Remontées mécaniques, autre"
                              : window.location.pathname.includes("/it")
                              ? "Funivia, altro"
                              : window.location.pathname.includes("/en")
                              ? "Cable car, other"
                              : "Bergbahn, Andere"}
                          </Typography>
                        </Typography>
                      </Typography>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <p>Loading...</p>
            )}
            <TextField
              autoComplete="off"
              onChange={this.onChangeDoubleFunEnd}
              name="endPointName"
              className="updateFields"
              fullWidth
              id="outlined-helperText"
              label={
                window.location.pathname.includes("/fr")
                  ? "Indiquer l'arrêt de destination"
                  : window.location.pathname.includes("/it")
                  ? "Immettere destinazione"
                  : window.location.pathname.includes("/en")
                  ? "Destination"
                  : "Zielort eingeben"
              }
              value={this.props.state.endPointName}
              variant="outlined"
            />
            <ul className="searchResults">
              {this.state.endStations.map((stations) => (
                <li
                  onClick={() =>
                    this.resetEndSearchResultsHandler(
                      stations.Name,
                      stations.Code
                    )
                  }
                >
                  {stations.Name}
                </li>
              ))}
            </ul>
          </Container>
          <Container className="updateButtonSection">
            {window.location.pathname.includes("/fr") ? (
              <Button
                onClick={() => this.errorChecker()}
                className="updateButton"
                disableElevation
                variant="contained"
              >
                Mettre à jour
              </Button>
            ) : window.location.pathname.includes("/it") ? (
              <Button
                onClick={() => this.errorChecker()}
                className="updateButton"
                disableElevation
                variant="contained"
              >
                Aggiornare
              </Button>
            ) : window.location.pathname.includes("/en") ? (
              <Button
                onClick={() => this.errorChecker()}
                className="updateButton"
                disableElevation
                variant="contained"
              >
                Update
              </Button>
            ) : (
              <Button
                onClick={() => this.errorChecker()}
                className="updateButton"
                disableElevation
                variant="contained"
              >
                Aktualisieren
              </Button>
            )}
          </Container>
        </Container>
        <BottomNav
          btnParam={"hide"}
          backBtnText={
            window.location.pathname.includes("/fr")
              ? "RETOUR"
              : window.location.pathname.includes("/it")
              ? "INDIETRO"
              : window.location.pathname.includes("/en")
              ? "BACK"
              : "ZURÜCK"
          }
          backBtnState={this.props.state.back}
          backButtonHandler={this.props.backButtonHandler}
          btnText={
            window.location.pathname.includes("/fr")
              ? "Terminer l'enquête"
              : window.location.pathname.includes("/it")
              ? "Concludere sondaggio"
              : "Umfrage beenden"
          }
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Main);
