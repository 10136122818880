import React, { useEffect } from "react";
import "./App.css";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Routes from "./Routes";

function App() {
  useEffect(() => {
    const currentLanguage = window.location.href.split("/")[3];
    if (currentLanguage === "fr" || currentLanguage === "en") {
      window.location.href = window.location.href.replace(
        currentLanguage,
        "de"
      );
    }
  }, []);

  return (
    <div className="App-main">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Routes />
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default App;
